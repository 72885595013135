<template>
  <div class="o-layout u-margin-bottom-tiny c-exercise" :id="getID()" :class="{scheduled: status === 'exercise.status.scheduled' }" >
    <template v-if="exercise">
      <div class="o-layout__item u-1-of-1 u-1-of-6-at-extralarge c-exercise__status">
          <span class="c-exercise__status-label">{{$t(getStatus())}}</span>
          {{getExerciseLabel()}}<span v-if="this.pass > 0"> - {{this.pass}}</span>
      </div>

      <div class="o-layout__item u-1-of-1 u-5-of-6-at-extralarge o-layout--right u-padding-right-none">
        <div class="o-layout__item u-1-of-1 o-layout u-padding-right-none">
          <div class="o-layout__item u-3-of-5-at-small c-exercise__stripped-block c-exercise__participant"
               :class="{'u-5-of-7-at-large': noedit, 'u-3-of-7-at-large': ! noedit}">
            <div v-if="participation.bib"
                 class="c-exercise__bib">
              {{participation.bib}}
            </div>

            <div class="c-exercise__participant-info">
              <component
                :is="'participant-'+ participation.participantType"
                :participation="participation"
                class="c-exercise__participant-name"
                />

              <div class="c-exercise__cat">
                <span class="c-exercise__cat-label">{{$t('cat')}}</span>
                {{getCategory().name}}
                <span v-if="isTeamEvent && !noedit">
                  <br/>{{exercise.noTeam ? $t('exercise.noTeam') : $t('exercise.team')}}
                  (<a href="#" v-on:click.prevent="toggleTeam()">{{$t('panel.change')}}</a>)
                </span>
                <span v-if="useJoker && !noedit">
                  <br/>{{$t('joker')}}: {{exercise.joker ? $t('exercise.joker') : $t('exercise.nojoker')}}
                  (<a href="#" v-on:click.prevent="toggleJoker()">{{$t('panel.change')}}</a>)
                </span>
              </div>
            </div>
          </div>

          <div class="o-layout__item u-2-of-5-at-small u-2-of-7-at-large c-exercise__stripped-block c-exercise__results">
            <exerciseResult v-for="pass in getPasses()" :key="pass" :exercise="exercise" :pass="pass" />
          </div>

          <div v-if="!noedit" class="o-layout__item u-1-of-1-at-small u-2-of-7-at-large c-exercise__stripped-block c-exercise__buttons-block">
            <div class="c-exercise__button-group">
              <!-- Left top button: present/absent -->
              <div class="exercise-action c-button c-button--small c-button--tertiary"
                   v-if="status === 'present'"
                   v-on:click="setParticipationStatus('absent')">
                {{$t('participation.setAbsent')}}
              </div>
              <div class="exercise-action c-button c-button--small c-button--tertiary"
                   v-if="status !== 'present'"
                   v-on:click="setParticipationStatus('present')">
                {{$t('participation.setPresent')}}
              </div>

              <!-- Left bottom button: dns/undo dns/clear score -->
              <div class="exercise-action c-button c-button--small c-button--tertiary"
                   :class="{'c-button--disabled': status !== 'present'}"
                   v-if="exercise.status === 'scheduled'"
                   v-on:click="setExerciseStatus('dns')">
                {{$t('panel.dns')}}
              </div>
              <div class="exercise-action c-button c-button--small c-button--tertiary"
                   :class="{'c-button--disabled': status !== 'present'}"
                   v-if="exercise.status === 'finished'"
                   v-on:click="clearExercise()">
                {{$t('panel.clearExercise')}}
              </div>
              <div class="exercise-action c-button c-button--small c-button--tertiary"
                   :class="{'c-button--disabled': status !== 'present'}"
                   v-if="exercise.status === 'dns'"
                   v-on:click="setExerciseStatus('scheduled')()">
                {{$t('panel.restore')}}
              </div>

              <!-- Right button: always open -->
              <div class="exercise-action c-button c-button--large c-button--primary"
                   :class="{'c-button--disabled': status !== 'present' || exercise.status === 'dns'}"
                   v-on:click="openScore()">
                {{$t('panel.openScore')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else >
      <div>Missing exercise</div>
    </template>
  </div>
</template>

<script>
  import find from 'lodash/find'
  import range from 'lodash/range'

  import blockLib from '@/lib/block'
  import exerciseLib from '@/lib/exercise'
  import participantLib from 'client/lib/participant'
  import rounds from 'client/lib/rounds'

  export default {
    name: "blockExercise",
    components: {
      "participant-clubMember": require('./participantClubMember.vue').default,
      "participant-group": require('./participantGroup.vue').default,
      "exerciseResult": require('./exerciseResult.vue').default,
    },
    props: ['blockParticipation', 'exerciseTypeId', 'pass', 'noedit', 'panelId'],
    data: function() {
      return {
        passes: 1,
        status: 'present',
        isTeamEvent: false,
        useJoker: false,
      }
    },
    computed: {
      exercise: function() {
        const exerciseTypeId =
          blockLib.getBlockParticipationExerciseType(this.blockParticipation, this.exerciseTypeId)

        let exercise = exerciseLib.getExercise(
          this.blockParticipation.participationId, this.panel.roundIndex, exerciseTypeId)

        if (! exercise) {
          if (this.$store.state.exercise.exercise
            && this.$store.state.exercise.exercise.participationId === this.blockParticipation.participationId
            && this.$store.state.exercise.exercise.exerciseTypeId === exerciseTypeId
            && this.$store.state.exercise.exercise.roundIndex === this.panel.roundIndex) {
            exercise = this.$store.state.exercise.exercise;
          }
        }
        return exercise;
      },
      panel: function() {
        return find(this.$store.state.panels.items, item => { return item.id === this.panelId });
      },
      categories: function() {
        return this.$store.state.categories.items;
      },
      participation: function() {
        return participantLib.getParticipation(this.blockParticipation.participationId);
      },
      clubs: function() {
        return this.$store.state.clubs.items;
      },
      members: function() {
        return this.$store.state.members.items;
      },
      settings: function() {
        return find(this.$store.state.panels.configurations, item => {
          return item.panelId === this.panelId;
        });
      },
      eventDiscipline: function() {
        return this.$store.state.eventDisciplines.items.find(i => i.id === this.exercise.eventDisciplineId)
      },
    },
    created: function() {
      this.setStatus();
      if (this.exercise && this.exercise.config && this.exercise.config.passes) {
        this.passes = this.exercise.config.passes
      }
      this.useJoker = this.eventDiscipline.useJokers
    },
    watch: {
      participation: function() {
        this.setStatus();
      }
    },
    methods: {
      setStatus: function() {
        const partRound = find(this.participation.rounds, i => i.roundIndex === this.panel.roundIndex)
        this.status = partRound.status

        // evaluate if this is a team competition:
        const round = rounds.getRound(this.panel.roundIndex)

        const roundRanking = find(round.rankings, i => i.enabled
          && (i.rankingType === 'TEAM-AA' || i.rankingType === 'TEAM-EVENTS'))

        this.isTeamEvent = !! roundRanking
      },
      getID: function() {
        let id = 'ex-' + this.exercise.id;
        if (this.settings.splitPasses) {
          id += '-' + this.pass;
        }
        return id;
      },
      getCategory: function() {
        return find(this.categories, item => { return item.id === this.participation.categoryId });
      },
      getStatus: function() {

        if (this.status !== 'present') {
          return 'participation.status.' + this.status;
        }
        //const exercise = this.getExercise();
        if (this.exercise) {
          return 'exercise.status.' + this.exercise.status;
        }
        return 'exercise.status.missing';
      },
      getPasses: function() {
        if (parseInt(this.pass) === 0) {
          return range(this.passes);
        }
        else {
          return [this.pass-1];
        }
      },
      getExerciseLabel: function() {
        return participantLib.getExerciseLabel(this.participation, this.exercise.roundId, this.exercise.exerciseTypeId);
      },
      openScore: function() {
        this.$emit('open', {exerciseId: this.exercise.id, pass: this.pass===0 ? 1 : this.pass})
      },

      setParticipationStatus: function(newStatus) {
        this.$store.dispatch("participation.setStatus",
          { participationId: this.participation.id, roundIndex: this.panel.roundIndex, status: newStatus, force: false}).then(
          () => {},
          err => {
            console.log(err);
            if (err.status === 409 && err.body.error.name === 'ERR_EXERCISES') {
              this.$modal.show({
                title: this.$t('participation.hasExercises'),
                message: this.$t('participation.hasExercises.text'),
                onConfirm: () => {
                  this.$store.dispatch("participation.setStatus",
                    { participationId: this.participation.id, roundIndex: this.panel.roundIndex, status: newStatus, force: true});
                }
              });
            }
          },
        );
      },
      setExerciseStatus: function(newStatus) {
        this.$store.dispatch("exercise.setStatus", { exerciseId: this.exercise.id, status: newStatus});
      },

      clearExercise: function() {
        this.$modal.show({
          title: this.$t('panel.clearScores'),
          message: this.$t('panel.clearAlert.text'),
          onConfirm: () => {
            this.$store.dispatch('exercise.clearValues', {exerciseId: this.exercise.id, status: 'scheduled', pass: 0});
          }
        });
      },
      toggleTeam: function() {
        this.$store.dispatch("exercise.setNoTeam", {exerciseId: this.exercise.id, noTeam: ! this.exercise.noTeam});
      },
      toggleJoker: function() {
        this.$store.dispatch("exercise.setJoker", {exerciseId: this.exercise.id, joker: ! this.exercise.joker});
      },
    }
  };
</script>
